
export default {
  name: 'MoleculeFiltersPins3D',
  filters: {
    replace: function (st, rep, repWith) {
      return st.split(rep).join(repWith)
    }
  },
  data() {
    return {
      defaultMode: '',
    }
  },
  computed: {
    pinCategories() {
      return this.$store.getters?.getActiveProjectSettings?.building?.pinsData?.categories || []
    },
    allPinsData() {
      return this.$store.getters?.getActiveProjectSettings?.building?.pinsData?.pins || []
    },
    engine3d() {
      return this.$engine3d || window.engine3d
    },
    activeProject() {
      return this.$store.state.project.activeProject
    },
    projectFeatures() {
      return this.$store.getters?.featuresForProject(this.activeProject);
    },
    disableList() {
      return this.projectFeatures?.disableMyList;
    },
  },
  mounted() {
    window.addEventListener('resize', this.resizeListener)
    this.$nextTick(() => {
      this.resizeListener();
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeListener)
  },
  methods: {
    filterPins3D(category) {
      const manager = this.engine3d.getClientManager()
      if (manager) {
        manager.showPinsByCategory(category)
      }
      this.defaultMode = category
    },
    getPinCategoryData(id) {
      return this.pinCategories.find(c => c.id === id);
    },
    resizeListener(e) {
      if(window.innerWidth < 1200) {
        this.filterPins3D('none');
      } else {
        this.filterPins3D('');
      }
    },
  }
}
